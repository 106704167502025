import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { auth } from 'firebase';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  //@Input() project_id:string;
  @Input() project_path:string;
  @Output() callback_close:EventEmitter<any> = new EventEmitter();

  project_doc:AngularFirestoreDocument;
  project_dict:{};
  intervalID;
  project_dict_prev_stringified:string;
  save_request:boolean=false;
  subscription:Subscription;

  constructor(private afs: AngularFirestore,private _snackBar: MatSnackBar) {
  }
  close() {
    console.log('editor: close()');
    this.callback_close.emit();
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    console.log('editor: ngOnInit START');
    console.log('project_path: '+this.project_path);
    //this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id);
    //this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id);
    this.project_doc = this.afs.doc(this.project_path);
    console.log('editor: subscribe');
    this.subscription = this.project_doc.valueChanges().subscribe(next=> {
      if(next == null) {
        console.log('null project_doc');
        this._snackBar.open('Error: Cannot load project (missing, or you do not have permission).', '', {
          duration: 5000,
        });
        this.close(); 
      } else {
        this.project_dict = next;//could/should do strict type def here, really... <StyleshiftProjectDict>x;
        if(!this.project_dict['path']) {
          console.log('init project-path');
          this.project_dict['path'] = this.project_doc.ref.path;
          //this.save();
        }    
        //this.remember_state(); //if we don't remember state, we would autosave over what we just loaded, which is silly. Also, race conditions could do bad things.
        //this.check_preview_need(); //in case we open a project that needs previews
        console.log('editor: subscription');
        console.log(next);
      }
    }, error =>{
      console.log('ERROR subscribing...');
      this._snackBar.open('Error: Cannot load project (missing, or you do not have permission).', '', {
        duration: 5000,
      });
      console.log(error);
      this.close();
    });
    //this.intervalID = setInterval(x=>{this.save_if_changes();}, 10000); //Warning: if set much lower than 100ms, it could use a good chunk of CPU
    console.log('editor: ngOnInit END')
  }
  add_keyframe() {
    console.log('editor: add_keyframe START');
    if(this.project_dict['keyframes'] == undefined) {
      console.log('editor: keyframes undefined. adding array.');
      this.project_dict['keyframes'] = {};
    }
    //this.project_dict['keyframes'].push(null);//keyframe_data);
    this.project_dict['keyframes'][Object.keys(this.project_dict['keyframes']).length] = null;
    this.save();
    console.log('editor: add keyframe END. RESULT: ');
    console.log(this.project_dict);
  }
  delete_keyframe(index:number) {
    console.log('editor: delete_keyframe('+index.toString()+')');
    delete this.project_dict['keyframes'][index];
    let new_keyframes = {};
    let count=0;
    for(let key in this.project_dict['keyframes']) {
      console.log('key '+key+' -> key '+count.toString());
      new_keyframes[count]=this.project_dict['keyframes'][key];
      count+=1;
    }
    this.project_dict['keyframes'] = new_keyframes;
    this.save();
  }
  save_if_changes() {
    if(this.project_dict_prev_stringified != undefined) {
      if(JSON.stringify(this.project_dict) === this.project_dict_prev_stringified) {
        //console.log('No changes to save.');
      } else {
        console.log('Saving...');
        //potential here to log snapshots for undo/redo capabilities and archives of project edit states
        this.save();
      }
    }
  }
  remember_state() {
    this.project_dict_prev_stringified = JSON.stringify(this.project_dict); //remember last saved state
  }
  buf2hex(buffer) { // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
  }
  digest(message:string) {
    return message.replace('/','.');
  }
  digestddd(message) {
    let result = 'nothing';
    crypto.subtle.digest('SHA-256',new ArrayBuffer(message)).then(async buf => {
      console.log('digest: ');
      result = this.buf2hex(buf);
      console.log(result);
      console.log('digest('+message+'): '+result);
      return result;
    });
    //console.log('digest('+message+'): '+result);
    console.log('digest() last line');
    console.log(result);
    return result;
  }
  sha256(str) {
    let result = undefined;
    crypto.subtle.digest("SHA-256", new TextEncoder().encode(str)).then(buf => {
      result = Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
    return result;
  }
  set_preview_keys() {
    console.log('set_preview_keys(): START');
    let hash = '';
    for(let key in this.project_dict['keyframes']) {
      try {
        let message = (this.project_dict['width'].toString() +
                      this.project_dict['height'].toString() +
                      this.project_dict['keyframes'][key]['reference'] +
                      this.project_dict['keyframes'][key]['style'] + 
                      this.project_dict['keyframes'][key]['style_v_ref'].toString()
        );
        //hash = this.hashCode(message+hash); //deterministic hashcode based on this and all prior keyframes. Change at N will change keyframes N+X
        hash = this.hashCode(message); //individual only        
        this.project_dict['keyframes'][key]['preview_key'] = hash;
        /*
        crypto.subtle.digest('SHA-256',new ArrayBuffer(message)).then(buf => {
          this.project_dict['keyframes'][key]['preview_key'] = this.buf2hex(buf);
          console.log('set_preview_keys(): preview_key: '+this.project_dict['keyframes'][key]['preview_key']);
        });*/
        console.log('set_preview_keys(): preview_key after done: '+this.project_dict['keyframes'][key]['preview_key']);
      } catch (error) {
        console.log('keyframe not ready yet...');
      }
    }
    console.log('set_preview_keys(): DONE');
  }
  hashCode(message) {
    var hash = 0, i, chr;
    if (message.length === 0) return hash.toString();
    for (i = 0; i < message.length; i++) {
      chr   = message.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  }
  check_preview_need() {
    let need=false;
    if(!this.project_dict['previews']) {
      console.log('init previews');
      this.project_dict['previews'] = {};
    }
    try {
      for(let key in this.project_dict['keyframes']) {
        if(this.project_dict['keyframes'][key]['reference'].length>0 && this.project_dict['keyframes'][key]['style'].length>0 && 0<=this.project_dict['keyframes'][key]['style_v_ref'] && this.project_dict['keyframes'][key]['style_v_ref']<=1) {
          //ok, we have the necessary data for a preview... what's our unique_id?
          let unique_id = this.digest(
            this.project_dict['keyframes'][key]['reference']+
            this.project_dict['keyframes'][key]['style']+
            this.project_dict['keyframes'][key]['style_v_ref'].toString()
          );
          console.log('sha256 result: '+unique_id);
          console.log('ASK FOR A NEW PREVIEW!!');
          need=true;
          //set UID
          //let unique_id = Date.now().toString();
          //delete old preview
          let old_unique_id = this.project_dict['keyframes'][key]['unique_id'];
          console.log('old vs new unique_id');
          console.log(old_unique_id);
          console.log(unique_id);
          //todo: asset remains in storage....
          this.project_dict['keyframes'][key]['unique_id'] = unique_id; //name this keyframe with a uid
          this.project_dict['previews'][unique_id] = 'process'; //this will be safely overwritten
          this.project_dict['previews']['loop'] = 'process'; //this will be safely overwritten
          need=true;
          if(old_unique_id != unique_id) {
            delete this.project_dict['previews'][old_unique_id];
          } else {
            console.log('looks like this is the same thing...');
          }
          
          //this.project_dict['keyframes'][this.index]['preview'] = unique_id;
          //this.save()
        } else {
          console.log('no preview needed.');
          //no preview needed
        }  
      }
    } catch (error) {
      console.log('editor: check_preview_need(): error: ');
      console.log(error);
    }
    return need;
  }
  /*
  check_preview_need() {
    let needs_preview = false
    //console.log('needs_preview() checking for preview requirement...');
    if(!this.project_dict['previews']) {
      console.log('init previews array');
      this.project_dict['previews'] = [];
    }
    //console.log('has keyframes...');
    //for (let key in this.project_dict['previews']) {
      //let value = this.project_dict['previews'][key];
      // Use `key` and `value`
    //}
    this.project_dict['previews'].forEach(preview_url => {
      //console.log('forEach check keyframe');
      //console.log(keyframe);
      try {
        if(preview_url.startsWith('http') == false) {
          //console.log('forEach needs_preview() return true');
          needs_preview = true;
        }
      } catch (error) {
        //console.log('forEach needs_preview() error ');
        //console.log(error);
        //keyframe['preview'] not tagged for process.
      }
    });
    //for(let keyframe in this.project_dict['keyframes']) {
    //}
    //console.log('forEach********* DONE');
    if(needs_preview) {
      let data = {'path':this.project_path};
      this.afs.collection('preview_tasks').doc(auth().currentUser.uid+'.'+this.project_doc.ref.id).set(data).catch(error => {
        this._snackBar.open('Error requesting preview.', '', {
          duration: 3000,
        });
      });
    }
  }*/
  is_keyframe_valid(keyframe) {
    try {
      if(keyframe['reference'].length>0) {
        console.log('reference seems ok');
      }
      if(keyframe['style'].length>0) {
        console.log('style seems ok');
      }
      if(keyframe['style_v_ref']>=0 && keyframe['style_v_ref']<=1) {
        console.log('style_v_ref seems ok');
      }
    } catch (error) {
      console.log('kayframe is NOT valid');
      return false;
    }
    return true
  }
  are_previews_necessary() {
    console.log('request_preview_if_necessary(): START');
    let needs_preview = false; //assume we do
    for(let key in this.project_dict['keyframes']) {
      let preview_key = this.project_dict['keyframes'][key]['preview_key'];
      //do we have the valid stuff?
      console.log('keyframe['+key.toString()+'][preview_key] :'+preview_key.toString());
      try {
        if(this.is_keyframe_valid(this.project_dict['keyframes'][key])) {
          console.log('keyframe is valid... check for preview');
          if(!this.project_dict['previews'][preview_key]) {
            console.log('no data for preview_key. Must need a preview');
            needs_preview=true;
          } else {
            if(this.project_dict['previews'][preview_key].startsWith('http')) {
              console.log('Looks like there is already a valid preview for this: previews['+key.toString()+']');
            } else if(this.project_dict['previews'][preview_key].startsWith('process')) {
                console.log('already processing this preview, it seems..');
            } else {
                this.project_dict['previews'][preview_key] = 'process';
                console.log('Keyframe looks valid, and there is no preview, and we are not processing');
                needs_preview=true;
            }
          }
        }
      } catch (error) {
        //don't care
        console.log('Error: ');
        console.log(error);
      }
      /*
      if(needs_preview) {
        console.log('Force loop process');
        this.project_dict['previews']['loop'] = 'process'; //we always need this if any change
      }*/
    }
    return needs_preview;
  }
  request_save() {
    this.save_request = true;
  }
  save() {
    console.log('editor: save()');
    this.project_dict['uid'] = auth().currentUser.uid;
    this.project_dict['username'] = auth().currentUser.displayName;
    this.project_dict['touched'] = new Date().toUTCString();
    this.project_dict['name'] = this.project_doc.ref.id; //For safety, make sure the name matches the doc_id
    this.project_dict['path'] = this.project_doc.ref.path;
    this.set_preview_keys(); //digest of variables -> preview_key
    //let needs_preview = this.check_preview_need();
    let previews_necessary = this.are_previews_necessary();
    this.project_doc.set(this.project_dict).then(x=>{
      if(previews_necessary) {
        console.log('request_preview_if_necessary(): YUP! NEED PREVIEW!');
        let data = {'path':this.project_path};
        this.afs.collection('preview_tasks').doc(auth().currentUser.uid+'.'+this.project_doc.ref.id).set(data).catch(error => {
          this._snackBar.open('Error requesting preview.', '', {
            duration: 3000,
          });
        });
      }
    }).catch(error => {
      console.log(error);
      if(this.project_dict['public']) {
        this._snackBar.open('Error: Cannot save public project unless you own it.', '', {
          duration: 3000,
        });
      } else {
        this._snackBar.open('Cannot save project.', '', {
          duration: 3000,
        });  
      }
    }).then();
    this.remember_state();
  }
  render() {
    console.log('editor: render()');
    this.project_dict['render_progress_percent']=0;
    //this.project_dict['rendered_preview_url']='';
    /*
    try {
      this.project_dict['rendered_url'];
    } catch (error) {
    }*/
    //toss a COPY of this project to the render_tasks.
    //Why a copy? It's a snapshot of state. User might want to keep editing.
    this.save(); //to update touched, etc.
    this.afs.collection('render_tasks').add(this.project_dict);
  }
  edit() {
    delete this.project_dict['render_progress_percent'];
  }
  cancel() {
    this.project_dict['cancel']=true;
    delete this.project_dict['render_progress_percent'];
    this.save();
  }
}
