import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
//import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
//import { auth } from 'firebase';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-editor-upload-task',
  templateUrl: './editor-upload-task.component.html',
  styleUrls: ['./editor-upload-task.component.scss']
})

export class EditorUploadTaskComponent implements OnInit {

  @Input() file: File;
  //@Input() project_id: string;
  @Input() project_dict: {};
  @Input() file_class: string;
  @Output() save_event = new EventEmitter<any>();
  //project_doc:AngularFirestoreDocument;
  
  running:boolean=true;
  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL:string;
  //useremail:string;

  constructor(private storage: AngularFireStorage,private _snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log('upload task got file: ');
    console.log(this.file);
    try {
      this.startUpload();  
    } catch (error) {
      console.log('Error caught in startUpload()');
    }
  }

  startUpload() {
    console.log('startUpload()');
    //this.useremail = auth().currentUser.email;

    // The storage path
    //const path = 'users/'+auth().currentUser.uid+'/projects/'+this.project_id+'/'+this.file_class.toLowerCase()+'/'+this.file.name;
    //mirror FireStore paths
    const path = this.project_dict['path']+'/'+this.file_class+'/'+this.file.name;
    //const path = 'users/'+auth().currentUser.uid+'/projects/'+this.project_dict['name']+'/'+this.file_class+'/'+this.file.name;
    console.log('upload to: '+path);

    // Reference to storage bucket
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, this.file);
    
    this.percentage = this.task.percentageChanges();  
    

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async () =>  {
        try {
          this.downloadURL = await ref.getDownloadURL().toPromise();
          console.log('Upload finished! ');
          console.log(this.file.name+': '+this.downloadURL);
          //let asset_data = <KeyframeAsset>{};
          //asset_data.name = this.file.name;
          //asset_data.url = this.downloadURL;
          //asset_data.file_class = this.file_class.toLowerCase();
          this.project_dict[this.file_class][this.file.name] = this.downloadURL;
          this.save_event.emit();
        } catch (error) {
          this.downloadURL='';
          this._snackBar.open('Error uploading file: '+this.file.name, '', {
            duration: 5000,
          });
        }
        console.log('upload-task stopping');
        this.running=false;
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
