import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  approved:boolean;
  constructor(public auth: AuthService,private afs: AngularFirestore) {
  }
  onInit() {
    //console.log('app component on init');
  }
}
