import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';
//import { userInfo } from 'os';
//import { auth } from 'firebase';

@Component({
  selector: 'app-editor-upload-manager',
  templateUrl: './editor-upload-manager.component.html',
  styleUrls: ['./editor-upload-manager.component.scss']
})

export class EditorUploadManagerComponent implements OnInit {

  @Input() file_class: string;
  @Input() file_description: string;
  @Input() max_files: number;
  //@Input() proj_id: String;
  //@Input() project_id:string;
  @Input() project_dict:{};
  @Output() save_event = new EventEmitter<any>();

  //project_doc:AngularFirestoreDocument;
  //asset_urls = [];
  //String notifier
  //@Output() notify: EventEmitter<String> = new EventEmitter<String>();

  isHovering: boolean;

  files: File[] = [];

  constructor() {
  }
  ngOnInit() {
    if(!this.project_dict[this.file_class]) {
      console.log('init '+this.file_class+' dict');
      this.project_dict[this.file_class] = {}; //init if empty
    }
  }
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  onDrop(files: FileList) {    
    console.log('onDrop: ');
    console.log(files);
    for (let i = 0; i < Math.min(this.max_files,files.length) ; i++) {
      if(files.item(i).type.includes('image')) {
        this.files.push(files.item(i));
      }
    }
    console.log('to an array');
    console.log(this.files);
  }
  save() {
    this.save_event.emit();
  }
}
