import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';
//import { userInfo } from 'os';
//import { auth } from 'firebase';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() file_class: string;
  @Input() file_description: string;
  @Input() max_files: number;
  //@Input() proj_id: String;
  @Input() project_id:string;

  project_doc:AngularFirestoreDocument;
  asset_urls = [];
  //String notifier
  //@Output() notify: EventEmitter<String> = new EventEmitter<String>();

  isHovering: boolean;

  files: File[] = [];

  constructor(private afs: AngularFirestore) {
    
  }
  ngOnInit() {
    this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id);
    this.project_doc.collection('assets', ref => ref.where('file_class','==',this.file_class.toLowerCase())).valueChanges().subscribe(x=>{
      this.asset_urls = [];
      x.forEach(x => {
        console.log('add url: '+x.url)
        this.asset_urls.push(x.url);
      })
    });
  }
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {    
    console.log('onDrop: ');
    console.log(files);
    files.item(0).name;
    for (let i = 0; i < Math.min(this.max_files,files.length) ; i++) {
      if(files.item(i).type.includes('image')) {
        this.files.push(files.item(i));
      }
    }
    console.log('to an array');
    console.log(this.files);
  }
}
