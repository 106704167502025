import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-editor-resolution',
  templateUrl: './editor-resolution.component.html',
  styleUrls: ['./editor-resolution.component.scss']
})
export class EditorResolutionComponent implements OnInit {
  @Input() project_dict:{};
  @Input() max_window:number;
  @Input() _width:number;
  @Input() _height:number;
  @Output() save_event = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this._width = this.project_dict['width'];
    this._height = this.project_dict['height'];
    if(this.max_window == undefined) {
      this.max_window = 150;
    }
  }
  is_set() {
    if(this.project_dict['width'] == this._width && this.project_dict['height'] == this._height) {
      console.log('is_set');
      return true;
    }
    else {
      console.log('is not set');
      return false;
    }
  }
  set_project_res() {
    console.log('set_project_res');
    if(this._width>0)
      this.project_dict['width'] = this._width;
    if(this._height>0)
      this.project_dict['height'] = this._height;
    let long_edge = Math.max(this.project_dict['width'],this.project_dict['height'])
    this.project_dict['preview_width'] = Math.round(this.max_window*this.project_dict['width']/long_edge);
    this.project_dict['preview_height'] = Math.round(this.max_window*this.project_dict['height']/long_edge);
    this.save_event.emit();
  }
}