import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { auth } from 'firebase';
import { StyleshiftProject } from '../styleshift-project';
import { StyleshiftKeyframe } from '../styleshift-keyframe';
//import { KeyframeAsset } from '../keyframe-asset';

@Component({
  selector: 'app-style-shift-proj',
  templateUrl: './style-shift-proj.component.html',
  styleUrls: ['./style-shift-proj.component.scss']
})
export class StyleShiftProjComponent implements OnInit {
  project:StyleshiftProject;
  @Input() project_id:string;
  @Output() callback_close:EventEmitter<any> = new EventEmitter();

  project_doc:AngularFirestoreDocument;
  //keyframe_indexes:[number?];
  //keyframe_ids:[string];
  total_seconds:number;
  visible:boolean;
  validated_project:boolean;
  references:{};
  x_sizes:[number];
  y_sizes:[number];
  //invalid_reasons:[string];

  constructor(private afs: AngularFirestore) {
    this.visible=false;
  }

  ngOnInit() {
    //this.afs.collection('files/', ref => ref.where('size', '==', 'large'))
    this.project = <StyleshiftProject>{};
    
    this.x_sizes = [320];
    for(let x=this.x_sizes[0];x<=15360;x+=8) {
      this.x_sizes.push(x);
    }

    this.y_sizes = [320];
    for(let x=this.y_sizes[0];x<=3840;x+=8) {
      this.y_sizes.push(x);
    }
    
    //if none exist
    //this.project_id = 'placeholder';//this.db.createId();
    /*
    if(this.project_id) {
      console.log('Assigned project_id: '+this.project_id);
    } else {
      console.log('Autogenerate project_id');
      this.project_id = auth().currentUser.uid;
    }*/
    
    this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id);
    
    this.project.keyframe_ids = [];
    /*
    console.log('this.project_doc.get().forEach:::');
    this.project_doc.get().forEach(x =>{
      console.log(x);
    });
    console.log('======================');
    */
    /*
    this.afs.firestore.doc('/projects/'+this.project_id).get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          // do something
          console.log('project exists!');
        } else {
          console.log('project does not exist!');
          this.project.birthday = new Date().toUTCString();
          //this.project.keyframes = [];
          //this.project.assets = <KeyframeAsset>{};
          this.project.uid = auth().currentUser.uid;
          this.project_doc.set(this.project);
        }
      });
    */

    console.log('subscribed to: '+this.project_doc.ref.id);
    this.project_doc.valueChanges().subscribe(x =>{
      //console.log('project_doc.valueChanges() subscription triggered');
      if(x == null) {
        console.log('null project_doc');
      } else {
        this.project = <StyleshiftProject>x;
        //console.log(this.project);
        if(this.project.keyframe_ids == undefined) {
          //console.log('project.keyframe_ids == null');
          this.add_keyframe();
        }
        if(this.project.x_res == undefined) {
          this.project.x_res = 480;
        }
        if(this.project.y_res == undefined) {
          this.project.y_res = 400;
        }
        if(this.project != null) {
          this.visible=true;
        }/*
        while(this.keyframe_indexes.length<this.project.keyframes.length) {
          this.keyframe_indexes.push(this.keyframe_indexes.length);
          console.log('push');
        }
        while(this.keyframe_indexes.length>this.project.keyframes.length) {
          this.keyframe_indexes.pop();
          console.log('pop');
        }*/
      }
      this.check_project_validity(); //uh oh, race condition here...
    });
    this.project_doc.collection('keyframes').snapshotChanges().subscribe(items =>{
      let keyframe_project_ids = []
      items.forEach(item => {
        keyframe_project_ids.push(item.payload.doc.id);
      })
      //console.log('keyframe_project_ids:');
      //console.log(keyframe_project_ids);
      //check if we're referencing a keyframe_doc that does not exist
      this.project.keyframe_ids.forEach(keyframe_id => {
        if(keyframe_project_ids.includes(keyframe_id.toString())) {
          //console.log(keyframe_id+' is valid');
        } else {
          console.log(keyframe_id+' is not a valid keyframe_doc');
          this.project.keyframe_ids.splice(this.project.keyframe_ids.indexOf(keyframe_id),1);
          console.log('removed it.');
          this.update_project();
        }
      });
      keyframe_project_ids.forEach(keyframe_project_id => {
        if(this.project.keyframe_ids.includes(keyframe_project_id.toString())) {
          console.log(keyframe_project_id+' is still in use.');
        } else {
          console.log(keyframe_project_id+' is not in use.');
          //this.doc_ref.collection('keyframes').doc(keyframe_project_id).delete();
          //console.log('deleted it.');
        }
      });
    });

  }
  add_keyframe() {
    //console.log('add_keyframe()');
    //this.doc_ref.collection('keyframes').doc(''+this.project.keyframes.length).set({somekey:'hi'});
    //this.project.ref_urls[this.project.ref_urls]
    let keyframe = <StyleshiftKeyframe>{};
    if(this.project.keyframe_ids == null) {
      //console.log('init project.keyframe_ids');
      this.project.keyframe_ids = [];
    }
    //let index = this.project.keyframe_ids.length;

    keyframe.ref_url=null;
    keyframe.style_url=null;
    keyframe.preview_url=null;
    keyframe.color_influence=null;
    keyframe.n_frames=5*30;
    keyframe.n_seconds=null;
    
    //this.project.keyframes[this.project.keyframes.length] = keyframe;
    //this.project.keyframes[index] = keyframe;
    //this.doc_ref.update(this.project);
    //this.update_project();
    //console.log(this.project.keyframes);
    //console.log(this.project.mappedkeyframe.size);
    //console.log('adding to keyframes collection');
    //console.log(keyframe);
    let keyframe_ref = this.project_doc.collection('keyframes').add(keyframe);
    keyframe_ref.then(x=> {
      //console.log('push x.id '+x.id.toString());
      this.project.keyframe_ids.push(x.id.toString());
      //console.log(this.project.keyframe_ids);
      this.update_project();
    })
    
    //this.keyframe_ids[len(this.keyframe_ids)]
    //keyframe_ref.set(keyframe);
  }
  render() {
    //console.log('render() start');
    this.project.render_progress_percent = 0;
    this.project.rendered_url='';
    this.update_project();
    //add this
    let data = {uid:auth().currentUser.uid};
    let render_task = this.afs.collection('render_tasks').doc(this.project_id);
    render_task.set(data);
    //console.log('render() end');
  }
  close_project() {
    this.project_id = undefined;
  }
  edit_things() {
    //console.log('edit_project()');
    //todo: this is a bit messy
    this.project_doc.set({'render_progress_percent':-1},{merge:true});
  }
  update_project() {
    this.project_doc.set({'touched':new Date().toUTCString()},{merge:true});
    this.project.project_class = 'keyframedrefstyle';
    this.project_doc.set(this.project,{merge:true});
    //console.log("=============================");
    //console.log(this.project);
    //this.project_doc.update(this.project);
    //console.log('updated project');
    //console.log(this.project);
  }
  check_project_validity() {
    console.log('check_project_validity()');
    //let valid = true;
    this.validated_project = true;
    //this.invalid_reasons = [''];
    this.total_seconds = 0;
    try {
      if(this.project.keyframe_ids.length<2) {
        this.validated_project = false;
        //this.invalid_reasons.push('Not enough keyframes');
      }
      //console.log('check resolution...');
      if(this.project.x_res<320 || this.project.y_res<320 || this.project.x_res>1920 || this.project.y_res>1920) {
        //console.log('invalid resolution');
        this.validated_project = false;
        //this.invalid_reasons.push('Invalid resolution');
      }
      //this.afs.collection('projects').doc(this.project_id).collection('assets', ref => ref.where('file_class','==','Style')).valueChanges().subscribe(x=>{
      //this.doc_ref.collection('keyframes',col => col.where('valid','==',true).where('id','array-contains','something'));
      /*
      this.total_seconds = 0
      this.project.keyframes.forEach(keyframe => {
        try {
          this.total_seconds += keyframe.n_seconds;
          if(keyframe.valid != true) {
            this.validated_project = false;
            this.invalid_reasons.push('keyframe invalid');
          }
        } catch (error) {
          this.validated_project = false;
          this.invalid_reasons.push('Error validating keyframes');
        }
      });*/

      this.project.keyframe_ids.forEach(keyframe_id => {
        console.log('check up on keyframe_id: '+keyframe_id);
        this.project_doc.collection('keyframes').doc(keyframe_id).ref.get().then(x => {
          try {
            let keyframe = <StyleshiftKeyframe>x.data();
            console.log('add total seconds: '+x.id);
            this.total_seconds += keyframe.n_seconds;
            //console.log('keyframe validity: ');
            //console.log(keyframe);
            if(keyframe.valid == true) {
              //console.log('keyframe valid');
            } else {
              this.validated_project = false;
              //console.log('keyframe invalid, therefore project invalid');
            }
          } catch (error) {
            //console.log('error - project not valid');
            this.validated_project = false;
          }
          //keyframe.valid;
        });
        //if(keyframe.valid != true) {
          //valid=false;
        //}
      });
    } catch (error) {
      //console.log('project not valid');
      console.log('error:');
      console.log(error);
      this.validated_project = false;
    }
  }
}