import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import { auth } from 'firebase';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-projectwatcher',
  templateUrl: './projectwatcher.component.html',
  styleUrls: ['./projectwatcher.component.scss']
})
export class ProjectwatcherComponent implements OnInit {
  projects:{};
  //project_ids:string[];
  public_project_metadata:{};
  selected_project_path:string;
  input_value:string;
  entered_text:string;
  project_collection:AngularFirestoreCollection;
  public_project_collection:AngularFirestoreCollection;
  approved:boolean;
  notice:string;
  displayName:string;
  uid:string;
  new_project_message:string;
  show_all:boolean=true;
  @Input() new_project_name:string;

  constructor(private afs: AngularFirestore,private _snackBar: MatSnackBar) {
    //this.project_ids = [];
  }

  ngOnInit() {
    this.notice = '';
    this.afs.collection('users').doc(auth().currentUser.uid).valueChanges().subscribe(x => {
      this.approved = x['approved'];
    });

    this.afs.collection('notices').doc('notice').valueChanges().subscribe(x => {
      this.notice = x['notice'];
    });

    this.selected_project_path = undefined;
    //todo: this will get triggered on any project edit! That means if user has 100 projects, this will get hit with 100 projects on EVERY project edit.
    this.project_collection = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects');
    //this.project_collection.ref.where("")
    this.project_collection.snapshotChanges().subscribe(items => {
      console.log('projectwatcher snapshotChange');
      this.projects = {};
      items.forEach(item => {
        this.projects[item.payload.doc.id] = item.payload.doc.data();
      });
    });

    this.public_project_collection = this.afs.collection('public_projects');
    //This subscription will trigger when people share/unshare projects, not on project edits.
    this.public_project_collection.snapshotChanges().subscribe(items => {
      this.public_project_metadata = {};
      items.forEach(item => {
        this.public_project_metadata[item.payload.doc.id] = item.payload.doc.data();
      })
    });

    ////////////////////////////
    /*
    this.public_projects_collection = this.afs.collection('public_projects');
    this.public_projects_collection.ref.where("read_access", "array-contains", 'public').onSnapshot(items => {
      console.log('SNAPSHOT CHANGES')
      items.forEach(item =>{
        console.log('read_access: '+item.id);
      });
    });*/
    //console.log('collection group set');
    /*
    this.all_projects.snapshotChanges().subscribe(items => {
      console.log('all_projects items:');
      this.all_project_ids = []
      items.forEach(item => {
        console.log('id: '+item.payload.doc.id);
        this.all_project_ids.push(item.payload.doc.id);
      })
    });*/
    //this.afs.collectionGroup('projects',ref => ref.where('public','==',true).onSnapshot({next:{},error:{}}));
    ////////////////////////
    this.uid = auth().currentUser.uid;
    this.displayName = auth().currentUser.displayName;
    this._snackBar.open('welcome '+this.displayName+'!', '', {
      duration: 2000,
    });
  }
  delete_project(project_id) {
    this.project_collection.doc(project_id).delete().then(x=>{
      this.unshare_project(project_id); //if it exists
    }).catch(error => {
      this._snackBar.open('Error deleting project.', '', {
        duration: 5000,
      });
    });
  }
  /*
  open_public_project(project_id) {
    console.log('open public project: '+project_id);
    this.afs.collection('public_projects').doc(project_id).ref.get().then(x =>{
      console.log('selected: ');
      this.selected_project_path = x.get('path');
      console.log(x);
    })
  }*/
  open_project(project_path) {
    console.log('open project: '+project_path);
    this.selected_project_path = project_path;
  }
  copy_project(project_path) {
    console.log('Original project path: '+project_path);
    let project_name = project_path.slice(project_path.lastIndexOf('/')+1);
    console.log('project_name:'+project_name);
    let dateObj = new Date();
    project_name+=' copy('+dateObj.getFullYear()+'.'+(dateObj.getMonth()+1)+'.'+dateObj.getDate()+' '+dateObj.getHours()+':'+dateObj.getMinutes()+')';
    let new_project_path = 'users/'+auth().currentUser.uid+'/projects/'+project_name;
    console.log('new path: '+new_project_path);
    this.afs.doc(project_path).get()
    this.afs.doc(project_path).ref.get().then(doc => {
      let data = doc.data();
      data['name']=project_name;
      data['public']=false;
      this.afs.doc(new_project_path).set(data);
      console.log('copied!');
    });
    //this.afs.doc(project_path).ref.set({'hi':'hi'})
  }
  /*
  edit_project(project_id) {
    console.log('edit_project: '+project_id);
    this.selected_project_path = 'users/'+auth().currentUser.uid+'/projects/'+project_id;
    console.log('selected_project_path:'+this.selected_project_path);
  }*/
  unshare_project(project_id) {
    this.afs.collection('public_projects').doc(auth().currentUser.uid+'--'+project_id).delete();
  }
  share_project(event,project_id) {
    console.log('share_project()');
    //console.log(event);
    let project_path = 'users/'+auth().currentUser.uid+'/projects/'+project_id;
    this.afs.doc(project_path).ref.set({public:event.checked},{merge:true}).then(x=>{
      if(event.checked) {
        let data = {}
        data['path'] = project_path;
        data['name'] = project_id;
        data['displayName'] = auth().currentUser.displayName;
        data['photoURL'] = auth().currentUser.photoURL;
        this.afs.collection('public_projects').doc(auth().currentUser.uid+'--'+project_id).set(data);
        //this.afs.collection('public_projects').doc(project_id).set(data);
      } else {
        this.unshare_project(project_id)
        //this.afs.collection('public_projects').doc(auth().currentUser.uid+'--'+project_id).delete();
        //this.afs.collection('public_projects').doc(project_id).delete();
      }  
    });
    
    //console.log(project_id+': '+this.share)
    //console.log(event);
    
    //console.log('^event id:');
    //console.log(project_id);
    //this.afs.collection('public_projects').doc(project_id).set({'path':'users/'+auth().currentUser.uid+'/projects/'+project_id});

    /*
    this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(project_id).ref.get().then(x=>{
      console.log('share_project value: ');
      console.log(x);
      this.afs.collection('public_projects').doc(x.id).set(x.data());
      console.log('wrote to public');
    });*/
  }
  close_project() {
    this.selected_project_path = undefined;
  }
  public_projects_check() {
    for(let i in this.public_project_metadata) {
      return true;
    }
    return false;
  }
  add_new_project() {
    //console.log('add_new_project()');
    //if(this.projects.includes(this.new_project_name)) {
    if(this.projects[this.new_project_name]) {
      this._snackBar.open('Project already exists!', '', {
        duration: 5000,
      });
      //alert('Project already exists!');
      //this.new_project_message='Error! Project already exists.';
      //snackBar.open('Message archived');
    } else {
      try {
        this.project_collection.doc(this.new_project_name).set({
          'touched':0,
          'name':this.new_project_name
        }).then(x=>{
          this.new_project_message='';
          this.new_project_name='';
        });        
      } catch (error) {
        this.new_project_message='Error creating project.';
      }
    }
  }
}
