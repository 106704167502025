import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-keyframe',
  templateUrl: './editor-keyframe.component.html',
  styleUrls: ['./editor-keyframe.component.scss']
})
export class EditorKeyframeComponent implements OnInit {
  @Input() project_dict:{};
  @Input() index:number;
  @Input() last:boolean;
  @Input() min_duration:number=5;
  @Input() max_duration:number=60;
  
  @Output() save_event = new EventEmitter<any>();
  @Output() delete_event = new EventEmitter<any>();

  
  constructor() { }

  ngOnInit() {
    //this.save = new EventEmitter();
    this.skeleton_check();
  }
  save() {
    //console.log('editor-keyframe: check for need of preview')
    //this.check_preview();
    console.log('editor-keyframe: emit save');
    console.log('style_v_ref: '+this.project_dict['keyframes'][this.index]['style_v_ref']);
    this.save_event.emit();
  }
  /*
  buf2hex(buffer) { // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
  }
  name_myself() {
    let message = this.project_dict['keyframes'][this.index]['reference']+this.project_dict['keyframes'][this.index]['style']+this.project_dict['keyframes'][this.index]['style_v_ref'].toString();
    crypto.subtle.digest('SHA-1',new ArrayBuffer(message)).then(async buf => {
      console.log('digest: ');
      this.project_dict['keyframes'][this.index]['preview_key'] = this.buf2hex(buf);
      console.log('named myself: '+this.project_dict['keyframes'][this.index]['preview_key']);
    });
  }
  check_preview() {
    console.log('Preview?');
    this.name_myself();
    this.project_dict['keyframes'][this.index]['needs_preview'] = false;
    try {
      if(this.project_dict['keyframes'][this.index]['reference'].length>0 && this.project_dict['keyframes'][this.index]['style'].length>0 && 0<=this.project_dict['keyframes'][this.index]['style_v_ref'] && this.project_dict['keyframes'][this.index]['style_v_ref']<=1) {
        //we have everything required... but maybe this was already done
        if(this.project_dict['keyframes'][this.index]['preview'])
        if(this.project_dict['previews'][this.project_dict['keyframes'][this.index]['preview_key']]) {
          console.log('we already have a preview for this keyframe');
        } else {
          console.log('Looks like we need a preview!');
          this.project_dict['previews'][this.project_dict['keyframes'][this.index]['preview_key']] = 'process'; //this will be replaced with a URL by the server
          this.project_dict['keyframes'][this.index]['needs_preview'] = true;
        }
      } else {
        console.log('editor-keyframe: Keyframe is not complete yet.');
      }
    } catch (error) {
      console.log('error checking preview');
    }
  }*/
  skeleton_check() {
    let trigger_save = false;
    if(this.project_dict['keyframes'][this.index] == null) {
      this.project_dict['keyframes'][this.index] = {}; //init as dict
      console.log('init keyframe');
      trigger_save=true;
    }
    if(!this.project_dict['keyframes'][this.index]['style_v_ref']) {
      this.project_dict['keyframes'][this.index]['style_v_ref']=0.5;
      console.log('init style_v_ref');
      trigger_save=true;
    }
    if(!this.project_dict['keyframes'][this.index]['reference']) {
      this.project_dict['keyframes'][this.index]['reference']=null;
      console.log('init reference');
      trigger_save=true
    }
    if(!this.project_dict['keyframes'][this.index]['style']) {
      this.project_dict['keyframes'][this.index]['style']=null;
      console.log('init style');
      trigger_save=true;
    }
    if(!this.project_dict['keyframes'][this.index]['duration']) {
      this.project_dict['keyframes'][this.index]['duration']=5;
      console.log('init duration');
      trigger_save=true;
    }
    if(this.project_dict['keyframes'][this.index]['duration']>this.max_duration) {
      this.project_dict['keyframes'][this.index]['duration'] = this.max_duration;
      trigger_save=true;
    }
    if(this.project_dict['keyframes'][this.index]['duration']<this.min_duration) {
      this.project_dict['keyframes'][this.index]['duration'] = this.min_duration;
      trigger_save=true;
    }
    if(!this.project_dict['previews']) {
      this.project_dict['previews'] = {};
      trigger_save=true
    }
    if(trigger_save) {
      this.save();
    }
  }
  delete_keyframe() {
    console.log('delete_keyframe: START');
    this.delete_event.emit();
    //delete this.project_dict['keyframes'][this.index];

    //this.project_dict['keyframes'].splice(this.index, 1);
    //this.project_dict['previews'].splice(this.index, 1); //this is really annoying, actually.. These can get out of sync too easily.
    //this.project_dict['previews'][this.index] = 'process';
    //this.project_dict['previews'][this.index.toString()] = 'process'; //technically everything past this index....
    console.log('delete-keyframe: END');
    //this.save();
  }
  force_preview() {
    console.log('force_preview() for keyframe '+this.index.toString());
    try {
      //delete the preview cache if it exists
      if(this.project_dict['previews'][this.project_dict['keyframes'][this.index]['preview_key']]) {
        console.log('delete preview cache: '+this.project_dict['previews'][this.project_dict['keyframes'][this.index]['preview']]);
        delete this.project_dict['previews'][this.project_dict['keyframes'][this.index]['preview_key']];
      } else {
        console.log('No preview cache');
      }
      if(this.project_dict['keyframes'][this.index]['preview_key']) {
        console.log('delete preview pointer: '+this.project_dict['keyframes'][this.index]['preview_key']);
        delete this.project_dict['keyframes'][this.index]['preview_key'];
      } else {
        console.log('No preview pointer.');
      }
      this.save();
    } catch (error) {
      console.log(error);
    }
    try {
      console.log('Delete preview loop cache');
      delete this.project_dict['previews']['loop'];
    } catch (error) {
      console.log('error deleting loop preview');
    }
  }
}