import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Instructions ---->
// Replace configPlaceholder with your firebase credentials
import { firebaseConfig } from '../environments/environment';
import { DropzoneDirective } from './dropzone.directive';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';

//enable production mode
import {enableProdMode} from '@angular/core';
import { StyleShiftProjComponent } from './style-shift-proj/style-shift-proj.component';
import { KeyframeComponent } from './keyframe/keyframe.component';
import {MatSelectModule} from '@angular/material/select'; //import this for global use
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ProjectwatcherComponent } from './projectwatcher/projectwatcher.component';
import { ScrapComponent } from './scrap/scrap.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ProjectsComponent } from './projects/projects.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { AdminComponent } from './admin/admin.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { EditorComponent } from './editor/editor.component';
import { EditorKeyframeComponent } from './editor-keyframe/editor-keyframe.component';
import { EditorUploadManagerComponent } from './editor-upload-manager/editor-upload-manager.component';
import { ImageTileComponent } from './image-tile/image-tile.component';
import { EditorResolutionComponent } from './editor-resolution/editor-resolution.component';
import { EditorUploadTaskComponent } from './editor-upload-task/editor-upload-task.component';
import { EditorAssetGridComponent } from './editor-asset-grid/editor-asset-grid.component';
import { EditorRenderComponent } from './editor-render/editor-render.component';
import {MatExpansionModule} from '@angular/material/expansion';


enableProdMode();

@NgModule({
  declarations: [AppComponent, DropzoneDirective, UploaderComponent, UploadTaskComponent, StyleShiftProjComponent, KeyframeComponent, ProjectwatcherComponent, ScrapComponent, ProjectsComponent, AdminComponent, EditorComponent, EditorKeyframeComponent, EditorUploadManagerComponent, ImageTileComponent, EditorResolutionComponent, EditorUploadTaskComponent, EditorAssetGridComponent, EditorRenderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatSelectModule,//imported for global use
    MatSliderModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatGridListModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatExpansionModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
