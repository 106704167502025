import { Component, OnInit, Input, Output,} from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, } from '@angular/fire/firestore';
import { StyleshiftProject, } from '../styleshift-project'
//import { StyleshiftKeyframe } from '../styleshift-keyframe';
//import { Observable } from 'rxjs';
//import { KeyframeAsset } from '../keyframe-asset';
import { StyleshiftKeyframe } from '../styleshift-keyframe';
import { auth } from 'firebase';
//import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-keyframe',
  templateUrl: './keyframe.component.html',
  styleUrls: ['./keyframe.component.scss']
})


export class KeyframeComponent implements OnInit {
  @Input() project_id: string; //the project document
  @Input() index: number;//this keyframe number
  @Input() keyframe_id:string;
  //@Input() keyframe:StyleshiftKeyframe;
  //selected: Observable<string>;
  project: StyleshiftProject;
  keyframe: StyleshiftKeyframe;
  preview_id: string;
  //doc_ref: AngularFirestoreDocument;
  keyframe_doc: AngularFirestoreDocument;
  project_doc: AngularFirestoreDocument;
  styles: {};
  references: {};
  artists: {};
  //show_preview: boolean;
  //keyframe_index:number;
  temp_data:string;
  
  constructor(private afs: AngularFirestore) {
    console.log('keyframe construction');
    this.project = <StyleshiftProject>{};
  }

  ngOnInit() {
    //this.show_preview = false;
    //this.artists = {none:'None',alice:'Alice',bob:'Bob'};
    this.artists = {alice:'Alice'};
    
    console.log('ngOnIint keyframe_id: '+this.keyframe_id);
    this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc<StyleshiftProject>(this.project_id);
    this.keyframe_doc = this.project_doc.collection('keyframes').doc<StyleshiftKeyframe>(this.keyframe_id);
    //this.keyframe_doc_ref = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id).collection('keyframes').doc(this.keyframe_id);
    //this.animator_engines['alice'] = 'Alice';
    //this.animator_engines['bob'] = 'Bob';
    //afs.collection('items', ref => ref.where('size', '==', 'large'));

    //console.log('Keyframe number: '+this.keyframe_number.toString());
    //console.log(this.project);
    //s.selected.subscribe(x => {
      //console.log('selected: '+x);
    //});


    //this.keyframe = <StyleshiftKeyframe>{};
    //this.keyframe.ref_url='';
    //this.keyframe.style_url='';
    //this.keyframe.preview_url='';
    //this.keyframe_index = 0;
    //this.doc_ref = this.afs.collection('projects').doc(this.project_id);

    //this.afs.collection('projects').doc(this.doc_id).collection('assets', ref => ref.where('file_class','==','Style')).valueChanges().subscribe(x=>{
    this.project_doc.collection('assets', ref => ref.where('file_class','==','style')).valueChanges().subscribe(x=>{
    //this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id).collection('assets', ref => ref.where('file_class','==','Style')).valueChanges().subscribe(x=>{
        console.log('***************styles valuechanges');
      //console.log(x);
      this.styles = {};
      x.forEach(x => {
        this.styles[x.name]=x.url;
      })
    });
    this.project_doc.collection('assets', ref => ref.where('file_class','==','reference')).valueChanges().subscribe(x=>{
    //this.afs.collection('projects').doc(this.project_id).collection('assets', ref => ref.where('file_class','==','Reference')).valueChanges().subscribe(x=>{
      console.log('***************reference valuechanges');
      this.references = {};
      x.forEach(item => {
        this.references[item.name]=item.url;
      })
    });

    /*
    //preview watcher
    this.project_doc.collection('assets', ref => ref.where('file_class','==','preview')).valueChanges().subscribe(x=>{
      console.log('*****************preview valuechanges');
      this.project_doc.collection('assets').doc(this.preview_id).ref.get().then(x=> {
        if(x.exists) {
          this.keyframe.preview_url = x.data().url;
          console.log('keyframe['+this.index+']: OMG THERE IS A PREVIEW ASSET');
        } else {
          console.log('keyframe['+this.index+']: No preview');
        }
      });
    });*/
    
    this.keyframe_doc.valueChanges().subscribe(x =>{
      if(x == null) {
        console.log('keyframe_doc is null: '+this.keyframe_id);
      } else {
        console.log('keyframe_doc.valueChange');
        this.keyframe = <StyleshiftKeyframe>x;
        this.keyframe.artist = 'Alice'; //TODO: hardcoded artist :/
        if(this.keyframe.color_influence == undefined) {
          this.keyframe.color_influence = 0.5;
        }
        if(this.keyframe.n_seconds == undefined) {
          this.keyframe.n_seconds=5;
        }
        console.log('read keyframe from afs and updated');
      }
      //this.project.keyframes[this.index]
      this.project_doc.set({'touched':new Date().toUTCString()},{merge:true});
    });
    /*
    this.doc_ref.valueChanges().subscribe(x =>{
      this.project = <StyleshiftProject>x;
      this.check_preview();
      //anything else to do when things change?
    });*/
  }
  /*
  update_n_frames(val:number) {
    this.project.keyframes[this.index].n_frames=val;
    this.doc_ref.update(this.project);
    console.log(this.selected);
  }*/
  delete_me() {
    //this.project.keyframes.splice(this.index,1);
    //this.project.keyframe_ids.splice(this.index,1);
    //this.doc_ref.update(this.project);
    //this.doc_ref.collection('keyframes').doc(''+this.index)
    this.keyframe_doc.delete();
  }
  /*
  check_preview() {
    console.log('check_preview()');
    this.show_preview = false;
    try {
      if(this.keyframe.ref_url && this.keyframe.style_url && this.keyframe.artist) {
        this.show_preview = true;
      }
    } catch (error) {
      this.show_preview = false;
    }
  }*/
  reset_preview_url() {
    console.log('reset_preview_url()');
    if(this.keyframe.ref_url && this.keyframe.style_url && this.keyframe.artist) {
      this.keyframe.preview_url = 'work';
    } else {
      this.keyframe.preview_url = null;
    }
    this.save();
  }
  save() {
    console.log('save()');
    this.check_validity(); //check validity just before update
    this.keyframe_doc.set(this.keyframe);
  }
  check_validity() {
    console.log('check_validity()');
    //let keyframe = this.project.keyframes[this.index];
    let valid=true;
    try {
      if(this.keyframe.n_seconds<=0) {
        console.log('keyframe n_seconds invalid');
        valid=false;
      }
      if(this.keyframe.ref_url.length<1) {
        console.log('keyframe reference invalid');
        valid=false;
      }
      if(this.keyframe.artist.toLowerCase() == 'none') {
        //ok without style
      } else if (this.keyframe.style_url.length<1) {
        console.log('keyframe style invalid');
        valid=false;
      }    
    } catch(err) {
      console.log(err);
      valid=false;
    }
    if(valid) {
      try {
        console.log('Keyframe '+this.index+' valid!');
        this.keyframe.valid = true;
      } catch(err) {
        console.log('could not record keyframe validity');
      }  
    }
  }
}