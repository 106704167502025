import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-editor-render',
  templateUrl: './editor-render.component.html',
  styleUrls: ['./editor-render.component.scss']
})
export class EditorRenderComponent implements OnInit {

  @Input() project_dict:{};
  @Output() render_event = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  render() {
    this.render_event.emit();
  }
  toggle_loop(event) {
    this.project_dict['loop'] = event.checked;
  }
  end_text() {
    if(this.project_dict['loop']) {
      return 'First frame';
    } else {
      return 'End.';
    }
  }
  time_sum() {
    let x=0;
    let last_added=0;
    for(let key in this.project_dict['keyframes']) {
      last_added = this.project_dict['keyframes'][key]['duration'];
      x+=last_added;
    }
    if(this.project_dict['loop']==false) {
      x-=last_added;
    }
    return x;
  }
  loop_url() {
    if(this.project_dict['loop']) {// && this.project_dict['previews']['loop']) { //if animated
      //return this.project_dict['previews']['loop']; //if animated
      console.log('loop preview_key: '+this.project_dict['keyframes']['0']['preview_key']);
      console.log('loop url: '+this.project_dict['previews'][this.project_dict['keyframes']['0']['preview_key']]);
      return this.project_dict['previews'][this.project_dict['keyframes']['0']['preview_key']];
    } else {
      //{{end_text()}}
      return '';//this.end_text();
    }
  }
}
