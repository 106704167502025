import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { auth } from 'firebase';
import { StyleshiftProject } from '../styleshift-project';
import { KeyframeAsset } from '../keyframe-asset';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() project_id: string;
  @Input() file_class: string;
  project_doc:AngularFirestoreDocument;
  

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL:string;
  //useremail:string;

  constructor(private storage: AngularFireStorage, private afs: AngularFirestore) { }

  ngOnInit() {
    console.log('upload task got file: ');
    console.log(this.file);
    //this.doc_ref = this.db.collection('projects').doc(this.project_id).collection(this.file_class).doc();

    this.project_doc = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').doc(this.project_id);
    this.startUpload();
  }

  startUpload() {
    console.log('startUpload()');
    //this.useremail = auth().currentUser.email;

    // The storage path
    //const path = 'users/'+auth().currentUser.uid+'/projects/'+this.project_id+'/'+this.file_class.toLowerCase()+'/'+this.file.name;
    //mirror FireStore paths
    const path = 'users/'+auth().currentUser.uid+'/projects/'+this.project_id+'/assets/'+this.file.name;
    console.log('upload to: '+path);

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async () =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        console.log('Upload finished! ');
        console.log(this.downloadURL);
        let asset_data = <KeyframeAsset>{};
        asset_data.name = this.file.name;
        asset_data.url = this.downloadURL;
        asset_data.file_class = this.file_class.toLowerCase();
        this.project_doc.collection('assets').doc(this.file.name).set(asset_data);
        //this.project_doc.collection('assets').add(asset_data);
        console.log('data: ');
        console.log(asset_data);
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
