import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
  users:{};
  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.users = {};
    this.afs.collection('users').valueChanges().subscribe(x => {
      x.forEach(user => {
        this.users[user['uid']] = user;
        if(!this.users[user['uid']]['approved']) {
          this.users[user['uid']]['approved'] = false;
        }
      });
    });
  }

  set(uid) {
    //console.log('set permissions: '+uid);
    this.afs.collection('users').doc(uid).set({'approved':this.users[uid]['approved']},{'merge':true})
  }
}
