import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-editor-asset-grid',
  templateUrl: './editor-asset-grid.component.html',
  styleUrls: ['./editor-asset-grid.component.scss']
})
export class EditorAssetGridComponent implements OnInit {
  @Input() project_dict:{};
  @Input() file_class:string;
  @Input() file_description:string;
  @Input() width:number;
  @Input() height:number;
  @Input() mode:string='cover'; //default cover the area, maintain aspect ratio

  constructor() { }

  ngOnInit() {
  }
  delete(item) {
    delete this.project_dict[this.file_class][item.key];
  }
}