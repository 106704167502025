import { Component ,OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export interface Car {
  make: string;
  model:string;
  wheels: {};
  owner?: string;
};

@Component({
  selector: 'app-scrap',
  templateUrl: 'scrap.component.html',
  /*
  template: `
    <div *ngIf="car">
      WE HAVE A CAR!<br>
      async json: {{car | async | json}}<br>
      <hr>
      model: {{(car|async)?.model}}<br>
      wheels: {{(car|async)?.wheels}}<br>
      owner: {{(car|async)?.owner}}<br>
      make: {{(car|async)?.make}}<br>
    </div>
  `*/
})
/*
      item: {{item}}<br>
      item: {{item | async}}

      {{ (item | async)?.name }}
*/
export class ScrapComponent implements OnInit {
  private carDoc: AngularFirestoreDocument<Car>;
  car: Observable<Car>;
  constructor(private afs: AngularFirestore) {
    //this.itemDoc = afs.doc<Item>('items/1');
    this.carDoc = afs.collection('cars').doc<Car>('s3')
    this.car = this.carDoc.valueChanges();
    //this.item = this.itemDoc.valueChanges();
    this.car.subscribe(x=> {
      console.log('car subscription');
    });
  }
  buttonclick() {
    console.log('you clicked a button');
    //this.carDoc.update({})
  }
  ngOnInit() {
    console.log('scrap init');
  }
  update(item: Car) {
    console.log('update()');
    this.carDoc.update(item);
    //this.itemDoc.update(item);
  }
}