import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore/public_api';
import { auth } from 'firebase';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    let x = this.afs.collection('users').doc(auth().currentUser.uid).collection('projects').get()
    console.log(x);
  }

}
