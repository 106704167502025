import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-tile',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.scss']
})
export class ImageTileComponent implements OnInit {
  @Input() url:string;
  //@Input() project_dict:{}
  @Input() color:string;
  @Input() width:number;
  @Input() height:number;
  @Input() mode:string='cover';
  @Output() click:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  click_event() {
    console.log('Clicked image-tile!');
    this.click.emit();
  }

}
